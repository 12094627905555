<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="toggleTable">
			<a-tabs default-active-key="1" @change="changeTab">
				<a-tab-pane key="1" tab="Pending"></a-tab-pane>
				<a-tab-pane key="2" tab="All" force-render> </a-tab-pane>
			</a-tabs>
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a @click="Detail(columnData.id)">{{ $t('page.checkSubmit') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiGetCheckDisburseList } from '@/api/check'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			unprocessed: true,
			branchOfficeList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityCard']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.applyInfo.cloums.creator',
					type: 'input',
					validateField: ['creatorName']
				},
				{
					label: 'table.applyInfo.cloums.createTime',
					type: 'range-picker',
					validateField: ['createTime', { initialValue: [] }]
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['storeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			return [
				{
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				// {
				// 	title: this.$t('table.applyInfo.cloums.caseStatus'),
				// 	dataIndex: 'statusCode',
				// 	align: 'center',
				// 	customRender: (v, o) => {
				// 		return this.$t(`table.applyInfo.recordCloums.nodeStatus.${v}`)
				// 	}
				// },
				{
					title: this.$t('table.applyInfo.loanApplyLabel.branchOffice'),
					dataIndex: 'storeName',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityCard',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.loanApproved'),
					dataIndex: 'loanApprovedAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.applyInfo.cloums.creator'),
					dataIndex: 'creatorName',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.createTime'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.submiter'),
					dataIndex: 'submitUserName',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.submitTime'),
					dataIndex: 'submitTime',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.nextReviewer'),
					dataIndex: 'nextCheckers',
					align: 'center',
					width: 200,
					customRender: (v, o) => {
						return v ? v.join(',') : '-'
					}
				},
				{
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 150,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiGetCheckDisburseList({ ...initParams, unprocessed: this.unprocessed })
		},
		changeTab(key) {
			this.unprocessed = key === '1'
			this.$refs.searchListTable.initTable(1)
		},
		Detail(id) {
			this.$router.push({
				path: 'loanDataReviewDetail',
				query: {
					id
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style scoped lang="less"></style>
